<template>
  <form
    class="space-y-6 px-4 py-8 sm:px-8 border border-gray-300 rounded-md bg-white"
    method="POST"
    target="_blank"
    @submit.prevent="submitContactDataForm"
  >
    <div>
      <h2 class="text-xl font-semibold leading-3 text-gray-900">Contact Details</h2>
    </div>

    <fieldset class="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
      <div class="sm:col-span-4 col-span-1">
        <InputField
          v-model:value="form.addressStreet.value"
          :errors="form.addressStreet.errors"
          @blur="form.addressStreet.validate()"
          label="Street address"
          type="text"
          text="street-address"
          id="street-address"
          placeholder=""
        />
      </div>
      <div class="sm:col-span-2 col-span-1">
        <InputField
          v-model:value="form.addressDetail.value"
          :errors="form.addressDetail.errors"
          @blur="form.addressDetail.validate()"
          label="Apt, Floor, or Suite"
          type="text"
          text="address-detail"
          id="address-detail"
          placeholder=""
        />
      </div>

      <div class="sm:col-span-2 sm:col-start-1">
        <InputField
          v-model:value="form.city.value"
          :errors="form.city.errors"
          @blur="form.city.validate()"
          label="City"
          type="text"
          text="city"
          id="city"
          placeholder=""
          autocomplete="address-level2"
        />
      </div>
      <div class="sm:col-span-2 sm:col-start-3">
        <SelectField
          :selectOptions="this.usStateData"
          v-model:value="form.state.value"
          label="State"
          :errors="form.state.errors"
        />
      </div>
      <div class="sm:col-span-2 sm:col-start-5">
        <InputField
          v-model:value="form.postalCode.value"
          :errors="form.postalCode.errors"
          @blur="form.postalCode.validate()"
          label="Zip / Postal code"
          type="text"
          text="postal-code"
          id="postal-code"
          placeholder=""
          autocomplete="postal-code"
        />
      </div>
    </fieldset>

    <div class="grid grid-cols-1 sm:grid-cols-6 gap-y-8 gap-x-6 border-t border-gray-900/10 pt-6">
      <div class="col-span-4">
        <InputField
          v-model:value="form.email.value"
          :errors="form.email.errors"
          @blur="form.email.validate()"
          label="Email"
          type="email"
          text="email"
          id="email"
          placeholder="example@email.com"
          autocomplete="email"
        />
      </div>
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-6 gap-x-6 gap-y-6">
      <div class="sm:col-span-2">
        <InputField
          v-model:value="form.mobilePhone.value"
          :errors="form.mobilePhone.errors"
          @blur="form.mobilePhone.validate()"
          @input="formatPhoneNumber('mobilePhone')"
          label="Cell phone"
          type="text"
          text="mobile-phone"
          id="mobile-phone"
          placeholder="XXXXXXXXXX"
          autocomplete="phone"
          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
          title="Phone number should be correct"
        />
      </div>
      <div class="sm:col-span-2 sm:col-start-3">
        <InputField
          v-model:value="form.homePhone.value"
          :errors="form.homePhone.errors"
          @input="formatPhoneNumber('homePhone')"
          label="Primary phone"
          type="text"
          text="home-phone"
          id="home-phone"
          placeholder="XXXXXXXXXX"
          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
          title="Phone number should be correct"
        />
      </div>
    </div>
    <fieldset
      v-if="paymentProviders[paymentProvider] !== EngagingNetwork"
      class="grid grid-cols-1 sm:grid-cols-full mt-2"
    >
      <label class="input--label">Preferred communication method</label>

      <div class="space-x-2 sm:space-x-6 flex">
        {{ form.isSmsPreferred.placeholder }}
        <div
          v-for="(option, i) in [
            form.isEmailPreferred,
            form.isSmsPreferred,
            form.isPhoneCallPreferred,
          ]"
          :key="i"
          class="items-center justify-center flex-1"
        >
          <input
            :id="option.label"
            type="checkbox"
            class="hidden peer"
            :value="true"
            v-model="option.value"
          />
          <label
            :for="option.label"
            class="block text-lg leading-6 font-medium text-gray-900 rounded-md border border-gray-300 py-3 px-3 text-center peer-checked:bg-gray-500 peer-checked:text-white"
            >{{ option.label }}</label
          >
        </div>
      </div>
    </fieldset>
    <template v-if="paymentProviders[paymentProvider] === EngagingNetwork">
      <div class="col-span-full">
        <label for="origin-source" class="input--label text-gray-900"
          >WFF’s work focuses around 6 key areas. Which do you feel most passionate about?</label
        >
        <select v-model="form.originSource.value" id="origin-source" class="input--text">
          <option value="" disabled selected>Select an option</option>
          <option v-for="option in originSourceOptions" :key="option.id" :value="option.id">
            {{ option.name }}
          </option>
        </select>
        <p v-if="form.originSource.errors.length > 0" class="input--error">
          {{ form.originSource.errors[0].message }}
        </p>
      </div>

      <fieldset class="mt-6 space-y-4">
        <legend class="text-lg font-semibold">Be a part of our community</legend>
        <p class="text-sm text-gray-600">
          We’ll send you updates on the impact of your donation – and how you can protect nature
          through activism, donation, events, and more. You can unsubscribe at any time. By
          providing your mobile number, you agree to receive recurring text messages from WWF. Text
          STOP to quit, HELP for info. Message and data rates may apply.
        </p>

        <div>
          <label class="block text-sm font-medium text-gray-900">
            I would like to get (or continue to get) email updates:
          </label>
          <div class="mt-2 flex space-x-4">
            <div>
              <input
                id="email-updates-yes"
                name="email-updates"
                type="radio"
                value="Y"
                v-model="form.emailUpdates.value"
                class="h-4 w-4 border-gray-300 text-gray-600 focus:ring-gray-500"
              />
              <label for="email-updates-yes" class="ml-2 text-sm text-gray-900">Yes</label>
            </div>
            <div>
              <input
                id="email-updates-no"
                name="email-updates"
                type="radio"
                value="N"
                v-model="form.emailUpdates.value"
                class="h-4 w-4 border-gray-300 text-gray-600 focus:ring-gray-500"
              />
              <label for="email-updates-no" class="ml-2 text-sm text-gray-900">No</label>
            </div>
          </div>
        </div>

        <div>
          <label class="block text-sm font-medium text-gray-900">
            I would like to get WWF Text messages:
          </label>
          <div class="mt-2 flex space-x-4">
            <div>
              <input
                id="text-messages-yes"
                name="text-messages"
                type="radio"
                value="Y"
                v-model="form.textMessages.value"
                class="h-4 w-4 border-gray-300 text-gray-600 focus:ring-gray-500"
              />
              <label for="text-messages-yes" class="ml-2 text-sm text-gray-900">Yes</label>
            </div>
            <div>
              <input
                id="text-messages-no"
                name="text-messages"
                type="radio"
                value="N"
                v-model="form.textMessages.value"
                class="h-4 w-4 border-gray-300 text-gray-600 focus:ring-gray-500"
              />
              <label for="text-messages-no" class="ml-2 text-sm text-gray-900">No</label>
            </div>
          </div>
        </div>
      </fieldset>
    </template>

    <div class="flex w-full space-x-2 sm:space-x-6">
      <button @click="prevStep" type="button" class="btn--secondary w-full">Back</button>
      <button type="submit" class="btn--primary w-full">Save &amp; Continue</button>
    </div>
  </form>
</template>

<script>
import { onBeforeMount, ref } from 'vue'
import SelectField from '@/components/inputs/SelectField'
import InputField from '@/components/inputs/InputField'
import { ContactDonorForm } from '@/services/users/'
import usStateData from '@/data/usStateData.js'
import { CampaignApi } from '@/services/campaign/'
import { useRoute, useRouter } from 'vue-router'
import { EngagingNetwork, paymentProviders } from '@/services/npo'

export default {
  name: 'DonorFormContact',
  components: {
    SelectField,
    InputField,
  },
  emits: ['contactData', 'stepNumber'],
  setup(_, { emit }) {
    const form = ref(new ContactDonorForm())
    const route = useRoute()
    const campaignId = route.params.id
    const fundraiserId = route.query.fundraiser
    const campaign = ref()
    const paymentProvider = ref()
    onBeforeMount(async () => {
      campaign.value = await CampaignApi.csc.customRetrieve({
        campaign: campaignId,
        fundraiser: fundraiserId,
      })
      paymentProvider.value = campaign.value.latestPaymentProvider?.paymentProvider
      if (paymentProvider.value !== 4) {
        form.value.originSource.value = originSourceOptions.value[0].id
      }
    })

    const originSourceOptions = ref([
      { id: 'Climate', name: 'Climate' },
      { id: 'Oceans', name: 'Oceans' },
      { id: 'Freshwater', name: 'Freshwater' },
      { id: 'Food', name: 'Sustainable Agriculture' },
      { id: 'Forests', name: 'Forests' },
      { id: 'Species', name: 'Wildlife' },
    ])

    // Method to format phone numbers
    function formatPhoneNumber(fieldName) {
      const phone = form.value[fieldName].value.replace(/\D/g, '') // Remove all non-digit characters
      let formatted = phone

      if (phone.length >= 4) {
        formatted = phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') // Format as XXX-XXX-XXXX
      } else if (phone.length >= 3) {
        formatted = phone.replace(/(\d{3})(\d{0,3})/, '$1-$2')
      }

      form.value[fieldName].value = formatted
    }

    function submitContactDataForm() {
      const unwrappedForm = form.value
      unwrappedForm.validate()
      if (!unwrappedForm.isValid) return

      // Emit the data to the parent component
      emit('contactData', unwrappedForm.value)
      emit('stepNumber', 2)
    }

    function prevStep() {
      emit('stepNumber', 0)
    }

    return {
      form,
      submitContactDataForm,
      usStateData,
      prevStep,
      formatPhoneNumber,
      originSourceOptions,
      EngagingNetwork,
      paymentProviders,
      paymentProvider,
    }
  },
}
</script>
