<template>
  <form
    class="space-y-6 px-4 py-8 sm:px-8 border border-gray-300 rounded-md bg-white"
    method="POST"
    target="_blank"
    @submit.prevent="submitPaymentDataForm"
  >
    <div>
      <h2 class="text-xl font-semibold leading-3 text-gray-900">Payment Details</h2>
    </div>

    <!-- Donation frequency -->
    <div class="col-span-full">
      <label class="input--label">Frequency</label>
      <div class="flex flex-wrap">
        <div
          v-for="option in frequencyOptions"
          :key="option.name"
          class="w-1/2 sm:w-1/2 lg:w-1/4 frequency-box"
        >
          <input
            :id="option.name"
            name="frequency"
            type="radio"
            :value="option"
            class="hidden peer frequency-radio"
            v-model="form.levelAutorepeat.value"
            :checked="option.id === form.levelAutorepeat.value?.id"
            @input="clearCustomAmount"
          />
          <label
            class="block p-3 text-center text-sm sm:text-base lg:text-lg text-gray-700 cursor-pointer peer-checked:bg-primary peer-checked:text-white"
            :for="option.name"
          >
            {{ option.name }}
          </label>
        </div>
      </div>
    </div>

    <!-- Donation amount -->
    <div class="col-span-full">
      <legend v-if="form.levelAutorepeat.value" for="amount" class="input--label text-gray-900">
        Donation Amount
      </legend>
      <div class="flex mt-2">
        <div class="flex flex-wrap">
          <fieldset
            v-for="option in frequencyOptions"
            :key="option.name"
            :id="option.name + '-amount'"
            class="sm:inline-flex items-end sm:space-x-5"
          >
            <span v-show="option.id === form.levelAutorepeat.value.id" class="inline-flex">
              <template
                v-for="(amount, index) in option.suggestedAmounts.sort((a, b) => b - a)"
                :key="amount"
              >
                <span>
                  <input
                    :id="'amount' + amount + '-' + index"
                    :name="option.value"
                    type="radio"
                    :value="amount"
                    class="hidden peer amount-radio"
                    v-model="form.donationAmount.value"
                    :checked="amount === form.donationAmount.value"
                    @input="clearCustomAmount"
                  />
                  <label
                    class="cursor-pointer peer-checked:bg-primary peer-checked:text-white frequency-box custom-amount"
                    :for="'amount' + amount + '-' + index"
                    >{{ '$' + amount }}</label
                  >
                </span>
              </template>
            </span>
            <div
              class="inline-flex items-center custom-amount-input"
              style="width: 40%"
              v-show="option.id === form.levelAutorepeat.value.id"
            >
              <span class="text-lg">$</span>
              <InputField
                v-model:value="form.customAmount.value"
                :errors="customAmountErrors"
                type="number"
                id="custom-amount-input"
                placeholder="0"
                @focus="onCustomAmountFocus"
                @blur="
                  () => {
                    onCustomAmountBlur()
                    checkCustomAmount()
                  }
                "
              />

              <span class="text-lg">.00</span>
              <div
                class="ml-5 frequency-box text-box"
                :class="{
                  'box--primary--small': isCustomAmountFocused || form.customAmount.value !== '',
                }"
              >
                Other Amount
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>

    <!-- Credit card -->
    <div class="col-span-full">
      <legend class="input--label text-gray-900">Card Details</legend>
      <div class="mt-2 space-y-3">
        <div class="flex space-x-3">
          <div class="w-1/2 min-w-0 flex-1">
            <input
              type="text"
              text="billing-name-first"
              id="billing-name-first"
              class="input--text"
              placeholder="First name"
              v-model="form.billingNameFirst.value"
            />
          </div>
          <div class="min-w-0 flex-1">
            <input
              type="text"
              text="billing-name-last"
              id="billing-name-last"
              class="input--text"
              placeholder="Last name"
              v-model="form.billingNameLast.value"
            />
          </div>
        </div>
        <div class="relative">
          <label for="card-number" class="sr-only">Card number</label>
          <input
            inputmode="tel"
            text="card-number"
            id="card-number"
            class="input--text"
            placeholder="Card number"
            v-model="form.cardNumber.value"
            v-cardformat:formatCardNumber
            @blur="maskCreditCard"
          />
          <input
            class="input--text absolute top-0 left-0"
            v-model="hiddenCreditCard"
            @click="unmaskCreditCard"
            v-if="maskCCVisible"
          />
        </div>
        <div class="flex space-x-3">
          <div class="w-1/2 min-w-0 flex-1">
            <label for="card-expiration-date" class="sr-only">Expiration date</label>
            <InputField
              v-model:value="form.cardExpDate.value"
              v-cardformat:formatCardExpiry
              :errors="form.cardExpDate.errors"
              @blur="form.cardExpDate.validate()"
              inputmode="tel"
              placeholder="MM / YYYY"
            />
          </div>
          <div class="min-w-0 flex-1">
            <label for="card-cvc" class="sr-only">CVC</label>
            <input
              inputmode="tel"
              text="card-cvc"
              id="card-cvc"
              class="input--text"
              placeholder="CVC"
              v-model="form.cardCvv.value"
              v-cardformat:formatCardCVC
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Billing address -->
    <div class="col-span-full space-y-3">
      <legend class="input--label text-gray-900">Billing Address</legend>

      <!-- Set billing to contact address checkbox -->
      <div class="mt-2 space-y-3">
        <div class="sm:col-span-4 col-span-1">
          <div class="flex items-start">
            <input
              id="same-address"
              text="same-address"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
              @change="autoSetAddress = !autoSetAddress"
            />
            <div class="leading-6 px-1 flex items-center">
              <label for="same-address" class="text-xs inline-block">Use same as contact</label>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-2 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
        <div class="sm:col-span-4 col-span-1">
          <InputField
            v-model:value="form.billingAddressStreet1.value"
            :errors="form.billingAddressStreet1.errors"
            @blur="form.billingAddressStreet1.validate()"
            label="Street address"
            type="text"
            text="street-address"
            id="street-address"
            placeholder=""
          />
        </div>
        <div class="sm:col-span-2 col-span-1">
          <InputField
            v-model:value="form.billingAddressStreet2.value"
            :errors="form.billingAddressStreet2.errors"
            @blur="form.billingAddressStreet2.validate()"
            label="Apt, Floor, or Suite"
            type="text"
            text="address-detail"
            id="address-detail"
            placeholder=""
          />
        </div>

        <div class="sm:col-span-2 sm:col-start-1">
          <InputField
            v-model:value="form.billingAddressCity.value"
            :errors="form.billingAddressCity.errors"
            @blur="form.billingAddressCity.validate()"
            label="City"
            type="text"
            text="city"
            id="city"
            placeholder=""
            autocomplete="address-level2"
          />
        </div>
        <div class="sm:col-span-2 sm:col-start-3">
          <SelectField
            :selectOptions="usStateData"
            v-model:value="form.billingAddressState.value"
            label="State"
            :errors="form.billingAddressState.errors"
          />
        </div>
        <div class="sm:col-span-2 sm:col-start-5">
          <InputField
            v-model:value="form.billingAddressZip.value"
            :errors="form.billingAddressZip.errors"
            @blur="form.billingAddressZip.validate()"
            label="Zip / Postal code"
            type="text"
            text="postal-code"
            id="postal-code"
            placeholder=""
            autocomplete="postal-code"
          />
        </div>
      </div>

      <!-- Email -->
      <div class="sm:col-span-2 col-span-1">
        <InputField
          v-model:value="form.donorEmail.value"
          :errors="form.donorEmail.errors"
          @blur="form.donorEmail.validate()"
          label="Email"
          type="email"
          text="email"
          id="email"
          placeholder="example@email.com"
          autocomplete="email"
        />
      </div>
    </div>

    <!-- Terms -->
    <div class="flex col-span-full">
      <div class="flex items-start">
        <input
          id="terms"
          text="terms"
          type="checkbox"
          class="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
          v-model="form.agreedToTerms.value"
          required
        />
      </div>
      <template v-if="paymentProvider === 'EngagingNetwork'">
        <div class="leading-6 px-2">
          <label for="terms" class="text-xs inline-block">
            By submitting this form, I understand that I am agreeing to make recurring charitable
            donations to World Wildlife Fund (WWF) in the amount and at the frequency selected
            above, and I authorize my credit/bank to make those payments from the indicate account.
            I acknowledge that I will not receive any goods/services in exchange for my donation. I
            will receive an acknowledgment of my initial payment via the email address I provided.
            Records of subsequent recurring payment(s) will appear on my credit card or bank
            statement.
            <br />
            <br />
            I acknowledge that my donations will be used in general support of WWF’s conservation
            mission and that I may cancel future recurring donations at any time by calling WWF at
            1-833-993-4376. I further understand that the individual(s) who solicited my donations
            to WWF are paid fundraisers employed by New Canvassing Experience, an agency hired by
            WWF to conduct face-to-face fundraising, which receives a fee for recruiting new WWF
            supporters.
            <br />
            <br />
            I understand that in submitting this form, I am providing certain personal information
            to WWF. WWF’s use of my information is subject to WWF’s privacy policy, available at
            https://www.worldwildlife.org/pages/privacy- policy. I agree to allow WWF and its
            authorized representatives (including NCE) to contact me by telephone, text, email, or
            mail, including regarding my donation. If I agree to take a picture with my fundraiser
            today, I give consent to World Wildlife Fund and its licensees to use my image, agree
            that I am not owed any compensation for use of my image, and release WWF from all claims
            relating to use of my image.
            <br />
            <br />
            I certify that I am at least 25 years old and make the recurring charitable donations
            described in this form of my own free will.</label
          >
        </div>
      </template>
      <template v-else>
        <div class="leading-6 px-2">
          <label for="terms" class="text-xs inline-block">
            I understand that I have spoken to a paid fundraiser employed by New Canvassing
            Experience, Inc., an agency paid a fee by {{ npoName }} to conduct this face-to-face
            fundraising campaign. I authorize the above amount to be donated to the
            {{ npoName }} from my credit card at the frequency selected until further notice. I also
            allow New Canvassing Experience and its partnered agencies to contact me regarding my
            donation via the contact information entered on this form. A record of each payment will
            appear on my credit card statement and will serve as a receipt for any subsequent
            donations.</label
          >
        </div>
      </template>
    </div>

    <!-- Custom Questions -->
    <DonorFormCustomQuestions
      @questionData="getQuestionData"
      v-bind:campaign-api-url="campaignApiUrl"
    />

    <div class="flex col-span-full space-x-6">
      <button @click="prevStep" type="button" class="btn--secondary w-full">Back</button>
      <button id="donor-data-submit" type="submit" class="btn--primary w-full">
        Submit Donation
      </button>
    </div>
  </form>
</template>

<script>
import { ref, watchEffect, computed } from 'vue'
import SelectField from '@/components/inputs/SelectField'
import InputField from '@/components/inputs/InputField'
import { PaymentDonorForm, ContactDonorForm } from '@/services/users/'
import DonorFormCustomQuestions from '@/components/DonorFormCustomQuestions.vue'
import usStateData from '@/data/usStateData.js'

export default {
  name: 'DonorFormPayment',
  components: {
    SelectField,
    InputField,
    DonorFormCustomQuestions,
  },

  props: {
    frequencyOptions: {
      type: Array,
      default: () => [],
    },
    campaignApiUrl: {
      type: String,
      default: '',
    },
    npoName: {
      type: String,
      default: '',
      required: true,
    },
    emailOptIn: {
      type: Boolean,
      default: true,
      required: true,
    },
    paymentProviders: {
      type: String,
      default: '',
      required: true,
    },
  },
  emits: ['donorData', 'stepNumber', 'submitForm'],

  setup(props, { emit }) {
    const isCustomAmountFocused = ref(false)
    const form = ref(new PaymentDonorForm())
    const donateSuccess = ref(false)
    const amountRadioButtons = ref(null)
    const frequency = ref('')
    const selectedFrequency = ref([])
    const amount = ref('')
    const selectedAmount = ref('')
    const minCustomAmount = ref(0)
    const terms = ref(false)
    const questionData = ref([])
    const autoSetAddress = ref(false)
    const maskCCVisible = ref(false)
    const maskCCLength = ref(0)
    const hiddenCreditCard = ref('')
    const customAmountErrors = ref([])
    // declaring card-brand here enables card brand name per https://github.com/wuori/vue-credit-card-validation
    const cardBrand = ref('')
    const paymentProvider = computed(() => props.paymentProviders)

    watchEffect(() => {
      form.value.donorEmailOptIn.value = props.emailOptIn

      if (form.value.customAmount.value) {
        form.value.donationAmount.value = null
      }

      if (autoSetAddress.value) {
        let contactData = {
          ...ContactDonorForm.create(JSON.parse(localStorage.getItem('DonorData'))?.donor ?? {})
            .toBillingAddressVal,
        }

        form.value = new PaymentDonorForm({ ...form.value.value, ...contactData })
      } else {
        form.value.billingAddressStreet1.value = ''
        form.value.billingAddressStreet2.value = ''
        form.value.billingAddressCity.value = ''
        form.value.billingAddressState.value = ''
        form.value.billingAddressZip.value = ''
        form.value.donorEmail.value = ''
      }
    })

    function checkCustomAmount() {
      customAmountErrors.value = [] // Reset errors on each check

      if (form.value.customAmount.value < form.value.levelAutorepeat.value?.minimum) {
        customAmountErrors.value.push({
          code: 'invalidMinValue',
          isRequired: true,
          message: `Minimum ${form.value.levelAutorepeat.value.name.toLowerCase()} donation is $${
            form.value.levelAutorepeat.value?.minimum
          }.`,
          min: form.value.levelAutorepeat.value?.minimum,
        })
      } else if (String(form.value.customAmount.value).includes('.')) {
        customAmountErrors.value.push({
          code: 'invalidDecimalValue',
          isRequired: true,
          message: 'Decimal value not allowed.',
        })
      }
    }

    function clearCustomAmount() {
      const unwrappedForm = form.value

      unwrappedForm.customAmount.value = ''
      customAmountErrors.value = []
    }

    function maskCreditCard() {
      if (form.value.cardNumber.value) {
        maskCCVisible.value = true
        maskCCLength.value = form.value.cardNumber.value.length
        hiddenCreditCard.value = '•'.repeat(maskCCLength.value)
      }
    }

    function unmaskCreditCard() {
      maskCCVisible.value = false

      const ccField = document.getElementById('card-number')
      ccField.focus()
    }

    function prevStep() {
      emit('stepNumber', 1)
    }

    function submitPaymentDataForm() {
      form.value.validate()

      if (!form.value.isValid || customAmountErrors.value.length) return
      emit('submitForm', {
        ...form.value.formVal,
        questionData: questionData.value,
        cardBrand: cardBrand.value,
      })
    }

    const onCustomAmountFocus = () => {
      isCustomAmountFocused.value = true
    }

    const onCustomAmountBlur = () => {
      isCustomAmountFocused.value = false
    }

    return {
      form,
      donateSuccess,
      amountRadioButtons,
      frequency,
      selectedFrequency,
      amount,
      selectedAmount,
      minCustomAmount,
      terms,
      submitPaymentDataForm,
      clearCustomAmount,
      prevStep,
      DonorFormCustomQuestions,
      questionData,
      autoSetAddress,
      usStateData,
      maskCreditCard,
      unmaskCreditCard,
      maskCCVisible,
      maskCCLength,
      hiddenCreditCard,
      checkCustomAmount,
      customAmountErrors,
      cardBrand,
      isCustomAmountFocused,
      onCustomAmountFocus,
      onCustomAmountBlur,
      paymentProvider,
    }
  },
  methods: {
    getQuestionData(data) {
      this.questionData = data
    },
  },
}
</script>
