import { z } from 'zod'
import { answerShape } from '../npo'
import { baseModelShape } from '../baseModel'
import { donorShape } from '../donor'
import { campaignShape } from '../campaign'
import { frequencyShape } from '../frequencies'
import { userShape } from '../users'

export const donationCreateShape = {
  amount: z.number().or(z.string()), //<-- response.contributionAmount
  donor: z.string(),
  campaign: z.string().uuid(),
  answers: z.array(z.string()).optional().nullable(),
  frequency: z.string().uuid().optional(),
  uniqueId: z.string().optional(),
  transactionId: z.string().optional(), // <-- response.submissionId
  consId: z.string().optional(),
  confirmationCode: z.string().optional(), // <-- response.confirmationID
  transactionErrorMsg: z.string().optional(),
  status: z.string().optional(),
  cardBrand: z.string().optional(),
  cardMaskedPan: z.string().optional(),
  cardExpirationDate: z.string().optional(),
  qrCode: z.string().optional(),
  cardHolderName: z.string().optional(),
}

export const donationShape = {
  ...baseModelShape,
  amount: z.number(),
  donor: z.string(),
  donorRef: z.object(donorShape),
  campaign: z.object(campaignShape),
  fundraiser: z.object(userShape),
  answerRefs: z.array(z.object(answerShape)),
  answers: z.array(z.string()),
  frequency: z.object(frequencyShape),
  uniqueId: z.string(),
  transactionId: z.string(),
  transactionErrorMsg: z.string(),
  consId: z.string(),
  confirmationCode: z.string(),
  status: z.string(),
  cardBrand: z.string(),
  qrCode: z.string(),
  cardHolderName: z.string(),
}
export const statusShape = {
  id: z.string(),
  name: z.string(),
}
export const frequencyIndexesShape = {
  id: z.string(),
  name: z.string(),
}
export const donationFilterValuesShape = {
  campaigns: z.object(campaignShape),
  fundraiseries: z.object(userShape),
  frequencies: z.object(frequencyIndexesShape),
  statuses: z.object(statusShape),
}

export const donationFiltersShape = {
  ordering: z.string().optional(),
  campaign: z.string(),
  dateRangeStart: z.date().or(z.string()),
  dateRangeEnd: z.date().or(z.string()),
  fundraiser: z.string(),
  frequency: z.string(),
  status: z.string(),
}

export const IRCSpringBoardDonationInputShape = {
  firstName: z.string(),
  lastName: z.string(),
  mail: z.string().email(),
  address: z.string(),
  city: z.string(),
  state: z.string(),
  zip: z.string().or(z.number()),
  cardNumber: z.string().or(z.number()),
  cardCvv: z.string().or(z.number()),
  amount: z.string().or(z.number()),
  frequency: z.string(),
  uniqueId: z.string().uuid(),
  cardExpDateMonth: z.number(),
  cardExpDateYear: z.number(),
  mobilePhone: z.string(),
}
export const IRCSpringBoardDonationOutputShape = {
  status: z.string(),
  submissionId: z.number(),
}

export const WWFEngaingNetworkDonationInputShape = {
  firstName: z.string(),
  lastName: z.string(),
  dateOfBirth: z.string(),
  mail: z.string().email(),
  address: z.string(),
  city: z.string(),
  state: z.string(),
  zip: z.string().or(z.number()),
  cardNumber: z.string().or(z.number()),
  cardCvv: z.string().or(z.number()),
  amount: z.string().or(z.number()),
  frequency: z.string(),
  uniqueId: z.string().uuid(),
  cardExpDateMonth: z.number(),
  cardExpDateYear: z.number(),
  emailUpdates: z.string(),
  textMessages: z.string(),
  originSource: z.string(),
  campaignId: z.string().uuid(),
  cardHolderName: z.string(),
  mobilePhone: z.string(),
  homePhone: z.string(),
}
export const WWFEngaingNetworkDonationOutputShape = {
  status: z.string(),
  transactionId: z.number(),
  message: z.string(),
}
export const canvassingOrgAdminChartInputShape = {
  start_date: z.string(),
  end_date: z.string(),
}
