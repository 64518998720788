import { ref, reactive, inject, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import donationStatusOptions from '@/services/donations/options'
import { sendCCInfo } from '@/services/donor/'
import { DonationApi } from '@/services/donations/'
import { DonorApi } from '../services/donor'
import { EngagingNetwork, SpringBoard, paymentProviders } from '@/services/npo/'
import * as Sentry from '@sentry/vue'
import { termsAgreementField } from '../services/users'

export function donationActions() {
  const donationStatuses = donationStatusOptions.DONATION_STATUSES
  const campaign = ref(null)
  const successModalOpen = ref(false)
  const failureModalOpen = ref(false)
  const route = useRoute()
  const campaignId = route.params.id
  let fundraiserId = route.query.fundraiser
  let qrCode = route.query.from_qrcode
  const mockPayment = route.query.mockpayment
  const donateFailureMessage = ref('')
  const donorId = ref('')
  const loading = ref(false)
  const donateSuccessMessage = ref('')
  const $everyaction = inject('$everyaction')
  const termsAgreement = ref(termsAgreementField)
  const paymentProvider = ref(null)
  const submissionStatus = ref(null)
  const submissionId = ref(null)
  const errorWWF = ref('')
  const failedAttempts = ref(0)

  const toggleSuccessModal = () => {
    if (successModalOpen.value) {
      location.reload()
    }
    successModalOpen.value = !successModalOpen.value
  }

  // Display failure modal if the donor fails to create
  const toggleFailureModal = () => {
    if (failedAttempts.value > 5) {
      location.reload()
    }
    failureModalOpen.value = !failureModalOpen.value
    failedAttempts.value += 1
  }

  const getMaskedPan = (cardNumber) => {
    const firstFour = cardNumber.slice(0, 6)
    const lastFour = cardNumber.slice(-4)
    const maskedPan = firstFour + '******' + lastFour
    return maskedPan
  }

  const generateErrorMsg = (e) => {
    // MK: According to the docs, the fieldError field is an array but from testing, I've only found it to return a string.
    // I've done a check in case it is an array but theoretically it shouldn't be
    // If the card is a test card, it seems to return a different data structure with the decline message
    let userMessage = e.message
    const fieldError = e.response?.data?.donationResponse?.errors?.fieldError
    const declineUserMessage = e.response?.data?.donationResponse?.errors?.declineUserMessage
    if (Array.isArray(fieldError)) {
      userMessage = fieldError.reduce((acc, key) => {
        return acc + ' ' + key
      }, '')
    } else if (fieldError) {
      userMessage = fieldError
    } else if (declineUserMessage) {
      userMessage = declineUserMessage
    }
    return userMessage
  }

  const handleFailure = (label, error, customMessage) => {
    donateFailureMessage.value = customMessage ? customMessage : error.message
    toggleFailureModal()
    loading.value = false
  }

  const createTransaction = async (paymentDetails) => {
    let transactionInfo
    try {
      const transaction = await sendCCInfo(paymentDetails)
      transactionInfo = transaction.donationResponse.donation
    } catch (e) {
      donateFailureMessage.value = generateErrorMsg(e)
    }
    return transactionInfo
  }

  const createDonor = async (fundraiserId) => {
    try {
      let data = JSON.parse(localStorage.getItem('DonorData'))
      data = data?.donor || data

      const donor = await DonorApi.csc.customCreate({
        ...data,
        fundraiser: fundraiserId,
      })
      donorId.value = donor.id
    } catch (e) {
      console.log(e)
      return handleFailure('Failed to create donor', e)
    }
  }

  const createDonation = async (
    campaignId,
    data,
    frequency,
    fundraiserId,
    questionData,
    transactionInfo,
    paymentData,
    qrCode,
  ) => {
    try {
      const answers = questionData.map((question) => {
        return question.question.answers[0].id
      })
      const maskedPan = getMaskedPan(paymentData.card_number)
      const donationInfo = {
        campaign: campaignId,
        cardBrand: data.cardBrand,
        cardMaskedPan: maskedPan,
        cardExpirationDate: paymentData.card_exp_date_month + '/' + paymentData.card_exp_date_year,
        donor: donorId.value,
        ...transactionInfo,
        transactionErrorMsg: donateFailureMessage.value,
        frequency: frequency,
        amount: data.otherAmount,
        fundraiser: fundraiserId,
        answers,
        status: transactionInfo ? donationStatuses.SUCCESS : donationStatuses.FAILURE,
        qrCode: qrCode,
      }
      await DonationApi.csc.customCreate(donationInfo)
    } catch (e) {
      Sentry.captureException(e)

      return handleFailure('Failed to create donation', e)
    }
  }

  const generateConfirmationCode = () => {
    return Math.floor(100000000 + Math.random() * 900000000).toString()
  }

  const generateUUID = () =>
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0
      const v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  const createDonationOf3rdPartyApi = async (data, uniqueId) => {
    if (!fundraiserId) {
      fundraiserId = localStorage.getItem('fundraiserId')
      qrCode = localStorage.getItem('qrCode')
    }

    if (!donorId.value) {
      await createDonor(fundraiserId)
    }
    if (submissionId.value) {
      localStorage.removeItem('transactionErrorMsg')
    }
    const transactionErrorMsg = localStorage.getItem('transactionErrorMsg')
    const maskedPan = getMaskedPan(data['card_number'])
    const donationInfo = {
      amount: data['otherAmount'],
      campaign: campaignId,
      frequency: data['frequency'],
      fundraiser: fundraiserId,
      donor: donorId.value,
      status: submissionStatus.value ? donationStatuses.SUCCESS : donationStatuses.FAILURE,
      transactionId: submissionId.value ? submissionId.value : '',
      confirmationCode: generateConfirmationCode(),
      qrCode: qrCode,
      uniqueId: uniqueId,
      cardMaskedPan: maskedPan,
      cardHolderName: `${data['billing.name.first']} ${data['billing.name.last']}`.trim(),
      transactionErrorMsg: transactionErrorMsg || '',
    }
    try {
      await DonationApi.csc.customCreate(donationInfo)
      if (!submissionId.value) {
        return handleFailure(
          '',
          '',
          'Please double check that all information is filled in correctly and try again.',
        )
      }
      localStorage.removeItem('DonorData')
      localStorage.removeItem('campaign')
      localStorage.removeItem('qrCode')
      localStorage.removeItem('fundraiserId')
      donateSuccessMessage.value = `Transaction Id: ${submissionId.value}`
      toggleSuccessModal()
    } catch (error) {
      Sentry.captureException(error)
      console.error('Error creating donation record:', error)
      Sentry.captureMessage(
        `Error occurred while creating donation record: ${error.message}`,
        'error',
      )
      return handleFailure('', '', 'Donation has been created but creating issue on your end.')
    } finally {
      loading.value = false
    }
  }

  const onEngagingNetworkSubmitForm = async (data) => {
    loading.value = true
    const donorData = JSON.parse(localStorage.getItem('DonorData')) || {}
    const uniqueId = generateUUID()
    const payload = {
      firstName: donorData.donor.firstName,
      lastName: donorData.donor.lastName,
      dateOfBirth: donorData.donor.dateOfBirth,
      mobilePhone: donorData.donor.mobilePhone,
      homePhone: donorData.donor.homePhone,
      cardHolderName: `${data['billing.name.first']} ${data['billing.name.last']}`.trim(),
      mail: data['donor.email'],
      address: `${data['billing.address.street1']} ${data['billing.address.street2']}`.trim(),
      city: data['billing.address.city'],
      state: data['billing.address.state'],
      zip: data['billing.address.zip'],
      cardNumber: data['card_number'],
      cardCvv: data['card_cvv'],
      amount: data['otherAmount'],
      frequency: data['sustaining.frequency'].toUpperCase(),
      uniqueId: uniqueId,
      cardExpDateMonth: Number(data['card_exp_date_month']),
      cardExpDateYear: Number(data['card_exp_date_year']),
      emailUpdates: donorData.donor.emailUpdates,
      textMessages: donorData.donor.textMessages,
      originSource: donorData.donor.originSource,
      campaignId: campaignId,
    }

    try {
      if (!submissionId.value) {
        const response = await DonationApi.csc.createEngaingNetworkDonation(payload)
        submissionStatus.value = response.status
        submissionId.value = response.transaction_id
        errorWWF.value = response.message
        localStorage.setItem('transactionErrorMsg', errorWWF.value)
      }
    } catch (error) {
      Sentry.captureException(error)
      const errorMessage =
        error?.response?.data?.message?.[0] || error?.response?.data?.[0] || error.message
      localStorage.setItem('transactionErrorMsg', errorMessage)
      console.error('Error creating donation:', errorMessage)
      Sentry.captureMessage(`Error occurred in onEngagingNetworkSubmitForm: ${error.message}`)
    }
    await createDonationOf3rdPartyApi(data, uniqueId)
  }
  const onSpringBoardSubmitForm = async (data) => {
    loading.value = true
    const donorData = JSON.parse(localStorage.getItem('DonorData')) || {}
    const uniqueId = generateUUID()
    const payload = {
      firstName: donorData.donor.firstName,
      lastName: donorData.donor.lastName,
      mail: data['donor.email'],
      address: `${data['billing.address.street1']} ${data['billing.address.street2']}`.trim(),
      city: data['billing.address.city'],
      mobilePhone: donorData.donor.mobilePhone,
      state: data['billing.address.state'],
      zip: data['billing.address.zip'],
      cardNumber: data['card_number'],
      cardCvv: data['card_cvv'],
      amount: data['otherAmount'],
      frequency: data['sustaining.frequency'],
      uniqueId: uniqueId,
      cardExpDateMonth: Number(data['card_exp_date_month']),
      cardExpDateYear: Number(data['card_exp_date_year']),
    }

    try {
      if (!submissionId.value) {
        const response = await DonationApi.csc.createSpringBoardDonation(payload)
        submissionStatus.value = response.status
        submissionId.value = response.submission_id
      }
    } catch (error) {
      Sentry.captureException(error)
      const errorMessage =
        error?.response?.data?.message?.[0] || error?.response?.data?.[0] || error.message
      localStorage.setItem('transactionErrorMsg', errorMessage)

      console.error('Error creating donation:', errorMessage)
      console.error(error)
      Sentry.captureMessage(`Error occurred in onSpringBoardSubmitForm: ${error.message}`)
    }
    await createDonationOf3rdPartyApi(data, uniqueId)
  }
  const onSubmitForms = async (data) => {
    loading.value = true
    paymentProvider.value = campaign.value.latestPaymentProvider?.paymentProvider
    if (paymentProviders[paymentProvider.value] === SpringBoard) {
      await onSpringBoardSubmitForm(data)
      return
    }
    if (paymentProviders[paymentProvider.value] === EngagingNetwork) {
      await onEngagingNetworkSubmitForm(data)
      return
    }
    const { formId, levelId, customDomain } = campaign?.value?.latestPaymentProvider
    const { frequency, questionData, ...paymentData } = data
    const additionalQuestions = questionData.reduce((acc, value) => {
      return { ...acc, [value.question.apiValue]: value.question.answers[0].text }
    }, {})
    let paymentDetails = {
      ...paymentData,
      additionalQuestions,
      formId,
      levelId,
      customDomain,
      mockPayment,
    }
    if (campaign.value.testMode) {
      paymentDetails.dfPreview = true
    }

    if (!donorId.value) {
      await createDonor(fundraiserId)
    }
    // fillForm(data)
    const transactionInfo = await createTransaction(paymentDetails)

    await createDonation(
      campaignId,
      data,
      frequency,
      fundraiserId,
      questionData,
      transactionInfo,
      paymentData,
      qrCode,
    )

    if (!transactionInfo) {
      loading.value = false
      return toggleFailureModal()
    }

    donateSuccessMessage.value = `Transaction Id: ${transactionInfo.transactionId}`
    toggleSuccessModal()
    localStorage.removeItem('DonorData')
    localStorage.removeItem('campaign')
    localStorage.removeItem('qrCode')
    localStorage.removeItem('fundraiserId')
    loading.value = false
  }

  const onSubmitFormsAlt = async (data, fundraiser, qrCode) => {
    // data = sample

    // get the selected frequency
    const freq = data.postVals.SelectedFrequency
    campaign.value = JSON.parse(localStorage.getItem('campaign'))
    // find its label in the data
    const label = data.formdef.form_elements
      .find((element) => element.name == 'ContributionInformation')
      .children.find((childElement) => childElement.name == 'SelectedFrequency')
      .options.find((option) => String(option.value) == String(freq)).display

    const frequency = campaign.value?.frequencies.find(
      (fr) => fr.value.toLowerCase() == label.toLowerCase(),
    )

    loading.value = true

    // PB - need to add a check here, if there is no levelid or formid then we should treat this as an inactive campaign!
    try {
      if (!donorId.value) {
        let userData = JSON.stringify({
          ...JSON.parse(localStorage.getItem('DonorData')),
          firstName: data.postVals.FirstName,
          lastName: data.postVals.LastName,
          email: data.postVals.EmailAddress,
          isEmailPreferred: data.postVals.YesSignMeUpForUpdatesForBinder,
          addressStreet: data.postVals.AddressLine1,
          city: data.postVals.City,
          state: data.postVals.StateProvince,
          postalCode: data.postVals.PostalCode,
          mobilePhone: data.postVals.MobilePhone
            ? `+${data.postVals.MobilePhoneInternationalDialingPrefix}${data.postVals.MobilePhone}`
            : '',
        })
        localStorage.setItem('DonorData', userData)
        Sentry.captureMessage(
          JSON.stringify({ data: userData, function: 'submitFormsAlt:createDonor' }),
        )
        await createDonor(fundraiser)
      }

      const donationInfo = {
        amount: data.response.contributionAmount,
        confirmationCode: data.response.confirmationID,
        transactionId: data.response.submissionId,
        campaign: campaign.value.id,
        donor: donorId.value,
        status: donationStatuses.SUCCESS,
        frequency: frequency?.id,
        fundraiser: fundraiser,
        qrCode: qrCode,
      }
      Sentry.captureMessage(
        JSON.stringify({ data: donationInfo, function: 'submitFormsAlt:createDonation' }),
      )

      await DonationApi.csc.customCreate(donationInfo)

      loading.value = false
    } catch (e) {
      console.log(e)
      Sentry.captureException(e)
    } finally {
      loading.value = false
    }
  }
  return {
    onSpringBoardSubmitForm,
    onSubmitForms,
    onSubmitFormsAlt,
    campaignId,
    fundraiserId,
    submissionStatus,
    submissionId,
    donorId,
    campaign,
    loading,
    getMaskedPan,
    toggleFailureModal,
    toggleSuccessModal,
    successModalOpen,
    failureModalOpen,
    donateSuccessMessage,
    donateFailureMessage,
    createDonor,
    termsAgreement,
    SpringBoard,
    EngagingNetwork,
    paymentProviders,
    paymentProvider,
    generateConfirmationCode,
    errorWWF,
  }
}
