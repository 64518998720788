import axiosInstance from '../AxiosClient'
import {
  donationShape,
  donationCreateShape,
  donationFiltersShape,
  IRCSpringBoardDonationInputShape,
  IRCSpringBoardDonationOutputShape,
  WWFEngaingNetworkDonationInputShape,
  WWFEngaingNetworkDonationOutputShape,
  donationFilterValuesShape,
  canvassingOrgAdminChartInputShape,
} from './models'
import {
  createApi,
  createCustomServiceCall,
  createCollectionManager,
  Pagination,
} from '@thinknimble/tn-models'
import fileDownload from 'js-file-download'

const DONATIONS_ENDPOINT = '/donations/'

const customCreate = createCustomServiceCall(
  {
    inputShape: donationCreateShape,
    outputShape: donationShape,
  },
  async ({ client, utils, input }) => {
    const { fundraiser, ...data } = input
    let formattedData = utils.toApi(data)
    const params = fundraiser ? `?fundraiser=${fundraiser}` : ''
    const res = await client.post(`${DONATIONS_ENDPOINT}${params}`, formattedData)
    return utils.fromApi(res.data)
  },
)

const createSpringBoardDonation = createCustomServiceCall(
  {
    inputShape: IRCSpringBoardDonationInputShape,
    outputShape: IRCSpringBoardDonationOutputShape,
  },
  async ({ client, utils, input }) => {
    const { ...data } = input
    let formattedData = utils.toApi(data)
    const params = '?SpringBoard=true'
    const res = await client.post(`${DONATIONS_ENDPOINT}${params}`, formattedData)
    console.log(res)
    return res.data
  },
)

const createEngaingNetworkDonation = createCustomServiceCall(
  {
    inputShape: WWFEngaingNetworkDonationInputShape,
    outputShape: WWFEngaingNetworkDonationOutputShape,
  },
  async ({ client, utils, input }) => {
    const { ...data } = input
    let formattedData = utils.toApi(data)
    const params = '?EngagingNetwork=true'
    const res = await client.post(`${DONATIONS_ENDPOINT}${params}`, formattedData)
    return res.data
  },
)

const generateExport = createCustomServiceCall(
  {
    inputShape: donationFiltersShape,
  },
  async ({ client, utils, input }) => {
    const { ...data } = input
    let formattedData = utils.toApi(data)

    client
      .post(`${DONATIONS_ENDPOINT}generate_donations_export/`, formattedData, {
        responseType: 'blob', // important
      })
      .then((response) => {
        fileDownload(response.data, 'donations_export.csv')
      })
  },
)

const retrieveFilterIndexes = createCustomServiceCall(
  {
    outputShape: donationFilterValuesShape,
  },
  async ({ client, utils }) => {
    const res = await client.get(`${DONATIONS_ENDPOINT}get_filter_values/`)
    return utils.fromApi(res.data)
  },
)
const donor_age_pie_chart = createCustomServiceCall(
  {
    inputShape: canvassingOrgAdminChartInputShape,
  },
  async ({ client, input, utils: { toApi } }) => {
    const { start_date, end_date } = toApi(input)
    const params = `?start_date=${start_date}&end_date=${end_date}`
    const res = await client.get(`${DONATIONS_ENDPOINT}donor_age_pie_chart/${params}`)
    return res.data
  },
)

const donation_amount_pie_chart = createCustomServiceCall(
  {
    inputShape: canvassingOrgAdminChartInputShape,
  },
  async ({ client, input, utils: { toApi } }) => {
    const { start_date, end_date } = toApi(input)
    const params = `?start_date=${start_date}&end_date=${end_date}`
    const res = await client.get(`${DONATIONS_ENDPOINT}donation_amount_pie_chart/${params}`)
    return res.data
  },
)

const number_of_donors_by_time = createCustomServiceCall(
  {
    inputShape: canvassingOrgAdminChartInputShape,
  },
  async ({ client, input, utils: { toApi } }) => {
    const { start_date, end_date } = toApi(input)
    const params = `?start_date=${start_date}&end_date=${end_date}`
    const res = await client.get(`${DONATIONS_ENDPOINT}number_of_donors_by_time/${params}`)
    return res.data
  },
)

//For Fundraisers

const get_fundraiser_performance_metrics = createCustomServiceCall(
  {
    inputShape: canvassingOrgAdminChartInputShape,
  },
  async ({ client, input, utils: { toApi } }) => {
    const { start_date, end_date } = toApi(input)
    const params = `?start_date=${start_date}&end_date=${end_date}`
    const res = await client.get(
      `${DONATIONS_ENDPOINT}get_fundraiser_performance_metrics/${params}`,
    )
    return res.data
  },
)

const get_campaign_performance_metrics = createCustomServiceCall(
  {
    inputShape: canvassingOrgAdminChartInputShape,
  },
  async ({ client, input, utils: { toApi } }) => {
    const { start_date, end_date } = toApi(input)
    const params = `?start_date=${start_date}&end_date=${end_date}`
    const res = await client.get(`${DONATIONS_ENDPOINT}get_campaign_performance_metrics/${params}`)
    return res.data
  },
)

const fundraiser_metrics = createCustomServiceCall(
  {
    inputShape: canvassingOrgAdminChartInputShape,
  },
  async ({ client, input, utils: { toApi } }) => {
    const { start_date, end_date } = toApi(input)
    const params = `?start_date=${start_date}&end_date=${end_date}`
    const res = await client.get(`${DONATIONS_ENDPOINT}fundraiser_metrics/${params}`)
    return res.data
  },
)

const donor_analytics_pie_charts_for_fundraisers = createCustomServiceCall(
  {
    inputShape: canvassingOrgAdminChartInputShape,
  },
  async ({ client, input, utils: { toApi } }) => {
    const { start_date, end_date } = toApi(input)
    const params = `?start_date=${start_date}&end_date=${end_date}`
    const res = await client.get(
      `${DONATIONS_ENDPOINT}donor_analytics_pie_charts_for_fundraisers/${params}`,
    )
    return res.data
  },
)

export const DonationApi = createApi(
  {
    baseUri: DONATIONS_ENDPOINT,
    client: axiosInstance,
    models: {
      entity: donationShape,
      create: donationCreateShape,
      extraFilters: donationFiltersShape,
    },
  },
  {
    customCreate,
    generateExport,
    createSpringBoardDonation,
    createEngaingNetworkDonation,
    retrieveFilterIndexes,
    donor_age_pie_chart,
    donation_amount_pie_chart,
    number_of_donors_by_time,
    get_fundraiser_performance_metrics,
    get_campaign_performance_metrics,
    fundraiser_metrics,
    donor_analytics_pie_charts_for_fundraisers,
  },
)

export const donationFunctions = () => {
  const donationFilters = {
    ordering: '-givaroo_id',
    campaign: '',
    dateRangeStart: '',
    dateRangeEnd: '',
    fundraiser: '',
    frequency: '',
    status: '',
  }

  const donationCollection = createCollectionManager({
    fetchList: DonationApi.list,
    filters: donationFilters,
    pagination: new Pagination(),
  })

  return {
    donationCollection,
    donationFilters,
  }
}
